<script
    lang="ts"
    setup
>
import type { VehicleGenericInformations } from '~/types/interfaces';
import type {
    Brand,
    BrandModel,
    VehicleBrandApiResponse,
    VehicleModelApiResponse,
    VehicleModelTypeApiResponse
} from '~/types/vehicle';

const userStore = useUserStore()
const {resetVehicleInformations, setVehicleInformations} = useVehicle()
const {links} = useConfigStore()

interface Props {
    isLabel?: boolean
}

withDefaults(defineProps<Props>(), {
    isLabel: () => true
})

const brands = ref<Brand[]>([])
const brandModels = ref<BrandModel[]>([])
const linkageTargets = ref<VehicleGenericInformations[]>([])
const defaultLinkedTarget: VehicleGenericInformations = {
    fromCache: 0,
    cache_ref: 0,
    cache_date: '',
    groupKey: '',
    default_car: false,
    supplierId: 0,
    supplierName: '',
    KType: 0,
    linkageTargetId: -1,
    linkageTargetType: '',
    description: '',
    mfrId: 0,
    mfrName: '',
    mfrShortName: '',
    vehicleModelSeriesId: '',
    vehicleModelSeriesName: '',
    beginYearMonth: '',
    rmiTypeId: 0,
    axleBodyTypes: [],
    vehicleImages: [],
    kbaNumbers: [],
    driveTypeKey: '',
    driveType: '',
    bodyStyleKey: '',
    bodyStyle: '',
    valves: '',
    fuelMixtureFormationTypeKey: '',
    fuelMixtureFormationType: '',
    fuelTypeKey: '',
    fuelType: '',
    engineTypeKey: '',
    engineType: '',
    horsePowerFrom: '',
    horsePowerTo: '',
    kiloWattsFrom: '',
    kiloWattsTo: '',
    cylinders: '',
    capacityCC: '',
    capacityLiters: '',
    engines: [],
    vehiclesInOperation: []
}
const defaultBrandModel = {
    id: -1,
    name: "",
    count: 0
}
const defaultBrand: Brand = {
    count: 0,
    id: -1,
    linkageTargetType: '',
    name: ''
}

const selectedBrand = ref<Brand>(defaultBrand)
const selectedBrandModel = ref<BrandModel>(defaultBrandModel)
const selectedLinkedTarget = ref<VehicleGenericInformations>(defaultLinkedTarget)

const getModelOfBrands = async () => {
    if (!brands.value.length) {
        const vehicleResponse = await $fetch<VehicleBrandApiResponse>('/api/catalog/getVehicleByModel', {
            query: {
                level: 1,
                userEmail: userStore.user.customerEmail
            }
        })
        brands.value = vehicleResponse.responseData.mfrFacets.counts.sort((a, b) => a.name.localeCompare(b.name));
    }
}

const getModelsForBrand = async () => {
    const vehicleResponse = await $fetch<VehicleModelApiResponse>('/api/catalog/getVehicleByModel', {
        query: {
            level: 2,
            level1Id: selectedBrand.value.id,
            userEmail: userStore.user.customerEmail
        }
    })
    brandModels.value = vehicleResponse.responseData.vehicleModelSeriesFacets.counts.sort((a, b) => a.name.localeCompare(b.name));
}

const getTypesForModel = async () => {
    const vehicleResponse = await $fetch<VehicleModelTypeApiResponse>('/api/catalog/getVehicleByModel', {
        query: {
            level: 3,
            level1Id: selectedBrand.value.id,
            level2Id: selectedBrandModel.value.id,
            userEmail: userStore.user.customerEmail
        }
    })
    linkageTargets.value = vehicleResponse.responseData.linkageTargets.sort((a, b) => a.description.localeCompare(b.description));
}

const selectVehicle = () => {
    resetVehicleInformations(true)
    setVehicleInformations(selectedLinkedTarget.value.linkageTargetId, '', undefined, undefined, selectedLinkedTarget.value)
    return navigateTo(links.catalog)
}
</script>

<template>
    <section class="box-immat section-inside text-secondary-color">
        <h2
            class="text-base font-medium"
            v-if="isLabel"
        >Par véhicule</h2>
        <form
            class="flex flex-col gap-3 md:flex-row"
            id="vehicleByModel"
            @submit.prevent="selectVehicle()"
        >
            <div class="flex gap-2 flex-col flex-1">
                <select
                    class="form-select"
                    id="brandName"
                    v-model="selectedBrand"
                    name="brand"
                    @change="getModelsForBrand()"
                    @click.stop="getModelOfBrands()"
                >
                  <option
                      :value="defaultBrand"
                      disabled
                      selected
                  >Saisir la marque</option>
                  <option
                      v-for="brand in brands"
                      :key="brand.id"
                      :value="brand"
                  > {{ brand.name }}</option>
                </select>
                <select
                    class="form-select"
                    id="vehicleModel"
                    v-model="selectedBrandModel"
                    :disabled="selectedBrand.id === -1"
                    name="model"
                    @change="getTypesForModel()"
                >
                    <option
                        :value="defaultBrandModel"
                        disabled
                        selected
                    >Saisir le modèle</option>
                    <option
                        v-for="model in brandModels"
                        :key="model.id"
                        :value="model"
                    > {{ model.name }}</option>
                </select>
                <select
                    class="form-select"
                    id="modelType"
                    v-model="selectedLinkedTarget"
                    :disabled="selectedBrandModel.id === -1"
                    name="type"
                >
                    <option
                        :value="defaultLinkedTarget"
                        disabled
                        selected
                    >Saisir le type</option>
                    <option
                        v-for="linkedTarget in linkageTargets"
                        :key="linkedTarget.linkageTargetId"
                        :value="linkedTarget"
                    > {{ linkedTarget.description }} {{ linkedTarget.driveType }}</option>
                </select>
            </div>
            <ElementsButtonLinear
                class="button-search-plate"
                preIcon="fa-solid-magnifying-glass"
                type="submit"
            />
        </form>
    </section>
</template>